// @flow
import * as React from 'react'

export default function InnerLogo() {
  return (
    <>
      <div className="root">
        <svg width="135" height="38">
          <use xlinkHref="#logo" />
        </svg>
      </div>

      <style jsx>{`
        .root {
          position: relative;
          display: inline-flex;
          padding-top: 3px;
        }
      `}</style>
    </>
  )
}
